import { getCurrentUser } from "./auth"
import Amplify, { API, graphqlOperation } from "@aws-amplify/api"

import { updateSession, deleteSession, updateUser } from "./graphql/mutations"
import { getUser, getSession } from "./graphql/queries"

import awsExports from "../aws-exports"
import awsExportsBackground from "../aws-exports-from-background"
Amplify.configure({
  ...awsExports,
  ...awsExportsBackground,
})

function updateSessionHelper(session) {
  return API.graphql(graphqlOperation(updateSession, { input: session })).then(
    evt => evt.data.updateSession
  )
}

function deleteSessionHelper(session) {
  return API.graphql(graphqlOperation(deleteSession, { input: session })).then(
    evt => evt.data.deleteSession
  )
}

function getSessionHelper(sessId) {
  return API.graphql(graphqlOperation(getSession, { id: sessId })).then(
    evt => evt.data.getSession
  )
}

function updateUserHelper(user) {
  return API.graphql(graphqlOperation(updateUser, { input: user })).then(
    evt => evt.data.updateUser
  )
}

function getUserHelper(userId) {
  return API.graphql(graphqlOperation(getUser, { id: userId })).then(
    evt => evt.data.getUser
  )
}

// TODO It's possible to subscribe to updates https://docs.amplify.aws/start/getting-started/data-model/q/integration/js#connect-frontend-to-api
// update local state when remote is changed

const updateSessionsInternal = (sessions, callback) => {
  if (!sessions.length) {
    callback([])
    return
  }
  const promises = []
  sessions.forEach(sess => {
    promises.push(updateSessionHelper(sess)) // https://docs.amplify.aws/lib/graphqlapi/getting-started/q/platform/js#enable-queries-mutations-and-subscriptions
  })

  Promise.all(promises)
    .then(() => {
      callback()
    })
    .catch(err => {
      console.error("error in aws_storage.updateSessions", err)
    })
}

export const init = () => {}

export const getUserFromDb = callback => {
  const proUsername = getCurrentUser().username

  if (proUsername) {
    getUserHelper(proUsername).then(user => {
      callback(user)
    })
  } else {
    callback(null)
  }
}

export const updateSessions = updateSessionsInternal

export const renameSession = (sessId, name, callback) => {
  updateSessionsInternal([{ id: sessId, name: name }], callback)
}

export const archiveSessions = (sessIds, callback) => {
  updateSessionsInternal(
    sessIds.map(id => ({ id: id, archived: true })),
    callback
  )
}

export const unArchiveSessions = (sessIds, callback) => {
  updateSessionsInternal(
    sessIds.map(id => ({ id: id, archived: false })),
    callback
  )
}

export const removeSessions = (sessIds, callback) => {
  if (!sessIds.length) {
    callback([])
    return
  }
  const promises = []
  sessIds.forEach(id => {
    promises.push(deleteSessionHelper({ id: id }))
  })

  Promise.all(promises)
    .then(() => {
      const proUsername = getCurrentUser().username

      getUserHelper(proUsername).then(user => {
        for (let id of sessIds) {
          const index = user.sessionIds.indexOf(id)
          if (index > -1) {
            user.sessionIds.splice(index, 1)
          }
        }
        updateUserHelper({
          id: proUsername,
          sessionIds: user.sessionIds,
        }).then(() => {
          callback()
        })
      })
    })
    .catch(err => {
      console.error("error in aws_storage.removeSessions", JSON.stringify(err))
    })
}

export const getAllSessions = callback => {
  const proUsername = getCurrentUser().username

  if (!proUsername) {
    console.error("proUsername not set")
    return
  }

  getUserHelper(proUsername).then(user => {
    const sessions = []
    const promises = []
    user.sessionIds.forEach(id => {
      promises.push(
        getSessionHelper(id).then(sess => {
          sessions.push(sess)
        })
      )
    })
    Promise.all(promises)
      .then(() => {
        callback(sessions)
      })
      .catch(err => {
        console.error(
          "error getting all sessions from AWS",
          JSON.stringify(err)
        )
      })
  })
}
