import React, { Component } from "react"
import PropTypes from "prop-types"
import classes from "./editSession.module.css"
import Modal from "./modal"
import editIcon from "../../images/edit.svg"

class EditSession extends Component {
  state = {
    open: false,
    name: "",
  }

  componentDidMount() {
    this.setState({ name: this.props.name })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({ name: this.props.name })
    }
  }
  finish() {
    this.hideModal()
    this.props.onEdited({ name: this.state.name })
  }
  showModal() {
    this.setState({ open: true })
  }
  hideModal() {
    this.setState({ open: false })
  }

  render() {
    return (
      <div className={classes.editSessContainer}>
        <img
          className={classes.sessionEditBtn}
          src={editIcon}
          onClick={event => {
            event.stopPropagation()
            this.showModal()
          }}
          alt="edit session"
        />
        <Modal open={this.state.open}>
          <div className={classes.editModalContainer}>
            <div className={classes.editModalContentContainer}>
              <div>Session name</div>
              <input
                type="text"
                onChange={event => {
                  this.setState({ name: event.target.value })
                }}
                onKeyDown={event => {
                  if (event.code === "Enter") {
                    this.finish()
                  }
                }}
                value={this.state.name}
              />
            </div>
            <button onClick={() => this.finish()}>Save</button>
          </div>
        </Modal>
      </div>
    )
  }
}

EditSession.propTypes = {
  name: PropTypes.string.isRequired,
  onEdited: PropTypes.func.isRequired,
}

export default EditSession
