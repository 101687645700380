import { loadStripe } from "@stripe/stripe-js"
import { getCurrentUser } from "./auth"
import { API } from "aws-amplify"

// https://stripe.com/docs/billing/subscriptions/checkout#webhooks
// https://medium.com/dzolo38/use-stripe-webhook-to-implement-your-business-logic-in-an-amplify-project-serverless-with-aws-f0c1bea52b25
// https://medium.com/dzolo38/https-medium-com-dzolo38-implement-the-new-stripe-checkout-workflow-with-a-react-amplify-project-serverless-78eccaac26e0
// https://dev.to/beezfedia/using-the-new-stripe-checkout-in-gatsby-aws-amplify-payment-confirmation-webhook-4lh4

export const createCheckoutSession = () => {
  return API.post("stripeApi", "/checkout", {
    response: true,
    body: {
      priceId: process.env.GATSBY_STRIPE_PRICE_ID,
      client_reference_id: getCurrentUser().username,
    },
  }).then(res => {
    return res.data.sessionId
  })
}

export const createCustomerPortalSession = stripeCustomerId => {
  return API.post("stripeApi", "/customer-portal", {
    response: true,
    body: {
      customerId: stripeCustomerId,
    },
  }).then(res => {
    return res.data.url
  })
}

export const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
)
