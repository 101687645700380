import React from "react"
import classes from "./proBanner.module.css"

class ProBanner extends React.Component {
  state = {
    error: undefined,
    loading: false,
  }

  componentDidMount() {
    this.mapPropsToState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.mapPropsToState()
    }
  }

  mapPropsToState() {
    if (this.props.error) {
      this.setState({ error: this.props.error })
    }
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.price}>$5.99/month</div>
          {!!this.state.error && (
            <div className={classes.error}>{this.state.error}</div>
          )}
          {!!this.state.loading && (
            <div className={classes.loading}>Loading...</div>
          )}
          <button
            ref={btn => {
              this.btn = btn
            }}
            className={classes.getProButton}
            onClick={() => {
              if (this.props.disableAfterClick) {
                this.btn.setAttribute("disabled", "disabled")
                this.setState({ loading: true })
              }
              this.props.onClick()
            }}
          >
            Get Pro
          </button>
          <div className={classes.text}>Eveyrthing in free version</div>
          <div className={classes.text}>Unlimited cloud storage</div>
          <div className={classes.text}>
            Dashboard with all your sessions accessible even from mobile
          </div>
          <div className={classes.text}>
            Browser history. Organized.{" "}
            <span className={classes.comingSoon}>Coming Soon</span>
          </div>
          <div className={classes.text}>
            Dark theme <span className={classes.comingSoon}>Coming Soon</span>
          </div>
          <div className={classes.text}>
            Archive sessions to reduce clutter even more
          </div>
          <div className={classes.text}>
            Even more organization using colors and tags{" "}
            <span className={classes.comingSoon}>Coming Soon</span>
          </div>
        </div>
      </div>
    )
  }
}

export default ProBanner
