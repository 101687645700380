import React from "react"
import { Router } from "@reach/router"
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
} from "@aws-amplify/ui-react"
import "../components/ui/app.css"

import Layout from "../components/layout"
import Details from "../components/details"
import Home from "../components/home"

// // <AmplifySignOut />
const App = () => (
  <AmplifyAuthenticator usernameAlias="email">
    <AmplifySignUp
      slot="sign-up"
      usernameAlias="email"
      formFields={[
        {
          type: "email",
          required: true,
        },
        {
          type: "password",
          required: true,
        },
      ]}
    />
    <AmplifySignIn slot="sign-in" usernameAlias="email" />
    <Layout>
      <Router>
        <Home path="/app" />
        <Details path="/app/account/*" />
      </Router>
    </Layout>
  </AmplifyAuthenticator>
)

export default App
