import React from "react"
import { navigate } from "gatsby"
import { Auth } from "aws-amplify"

import classes from "./details.module.css"
import ProBanner from "./ui/proBanner"

import { logout, getCurrentUser } from "../utils/auth"
import { getUserFromDb } from "../utils/db"
import {
  createCheckoutSession,
  stripePromise,
  createCustomerPortalSession,
} from "../utils/stripe"

class Details extends React.Component {
  state = {
    email: undefined,
    pro: false,
    stripeCustomerId: undefined,
    paymentError: undefined,
    loading: false,
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          email: getCurrentUser().email,
        }),
      300
    )
    this.getProDetails()
    this.checkOptionalParam()
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps["*"] !== this.props["*"]) {
  //     this.checkOptionalParam()
  //   }
  // }

  checkOptionalParam() {
    const optionalParam = this.props["*"]
    if (optionalParam.startsWith("paymentSuccess")) {
      setTimeout(() => {
        this.getProDetails()
      }, 2000)
    } else if (optionalParam.startsWith("paymentCanceled")) {
      this.setState({
        paymentError:
          "There was a problem processing your payment. Please try again.",
      })
    }
  }

  getProDetails() {
    getUserFromDb(user => {
      if (user) {
        this.setState({
          pro: user.isPro,
          stripeCustomerId: user.stripeCustomerId,
        })
      }
    })
  }

  navigateToStripe() {
    var sessionId
    createCheckoutSession()
      .then(sessId => {
        sessionId = sessId
        return stripePromise
      })
      .then(stripe => {
        return stripe.redirectToCheckout({
          sessionId: sessionId,
        })
      })
      .then(result => {
        if (result.error) {
          this.setState({ paymentError: result.error.message })
        }
      })
      .catch(error => {
        console.error("error navigating to stripe", error)
      })
  }

  redirectToCustomerPortal() {
    createCustomerPortalSession(this.state.stripeCustomerId).then(portalUrl => {
      window.location.href = portalUrl
    })
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>Account Settings</div>
          <button
            className={classes.logoutButton}
            onClick={() =>
              Auth.signOut()
                .then(logout(() => navigate("/")))
                .catch(err => console.log("error:", err))
            }
          >
            Log out
          </button>
        </div>
        <div className={classes.content}>
          <div className={classes.item}>
            <div>
              <div className={classes.itemTitle}>Email</div>
              <div className={classes.itemSubTitle}>{this.state.email}</div>
            </div>
          </div>
          <div className={classes.item}>
            <div>
              <div className={classes.itemTitle}>Account plan</div>
              <div className={classes.itemSubTitle}>
                {this.state.pro ? "Pro" : "Free"}
              </div>
            </div>

            {this.state.pro && (
              <div style={{ justifySelf: "flex-end" }}>
                {!!this.state.loading && (
                  <div className={classes.loading}>Loading...</div>
                )}
                <button
                  className={classes.itemButton}
                  ref={btn => {
                    this.btn = btn
                  }}
                  onClick={() => {
                    this.btn.setAttribute("disabled", "disabled")
                    this.setState({ loading: true })
                    this.redirectToCustomerPortal()
                  }}
                >
                  Manage Pro account
                </button>
              </div>
            )}
          </div>
        </div>
        {!this.state.pro && (
          <>
            <div style={{ paddingTop: 40 }} />
            <ProBanner
              disableAfterClick={true}
              error={this.state.paymentError}
              onClick={() => this.navigateToStripe()}
            />
          </>
        )}
      </div>
    )
  }
}

export default Details
