import React from "react"
import { Link } from "gatsby"
import Sessions from "./ui/sessions"
import classes from "./home.module.css"

import { addCallback, removeCallback } from "../utils/auth"
import {
  getAllSessions,
  removeSessions,
  archiveSessions,
  unArchiveSessions,
  updateSessions,
  getUserFromDb,
} from "../utils/db"

class Home extends React.Component {
  state = {
    pro: false,
    allSessions: undefined,
  }

  componentDidMount() {
    this.mounted = true
    this.getAllDataDelayed()
    this.callbackId = addCallback("signIn", data => {
      if (this.mounted) {
        this.getAllData()
      }
    })
  }

  componentWillUnmount() {
    this.mounted = false
    removeCallback("signIn", this.callbackId)
    this.callbackId = null
  }

  getAllDataDelayed() {
    setTimeout(() => this.getAllData(), 300)
  }

  getAllData() {
    getUserFromDb(user => {
      if (user) {
        this.setState({
          pro: user.isPro,
        })

        if (user.isPro) {
          getAllSessions(allSessions => {
            for (let sess of allSessions) {
              if (!sess.name) {
                sess.name = sess.tabs[0].title
              }
            }
            allSessions.sort((a, b) => ("" + a.name).localeCompare(b.name)) // sort by name
            this.setState({ allSessions: allSessions })
          })
        }
      }
    })
  }

  render() {
    return (
      <div>
        <div style={{ paddingTop: 100 }} />
        {this.state.pro ? (
          this.renderPro()
        ) : (
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <Link to="/app/account">Upgrade to Pro</Link> to see and manage all
            your sessions here.
          </div>
        )}
      </div>
    )
  }

  renderPro() {
    let activeSess
    let archivedSess

    if (this.state.allSessions) {
      activeSess = []
      archivedSess = []
      for (let sess of this.state.allSessions) {
        if (sess.archived) {
          archivedSess.push(sess)
        } else {
          activeSess.push(sess)
        }
      }
    }

    return (
      <>
        <Sessions
          sessions={activeSess}
          removeText="Archive session"
          onRemoveSession={sessId => {
            this.optimisticUISessArchived(sessId)
            archiveSessions([sessId], () => this.getAllDataDelayed())
          }}
          onEditSess={(sessId, editedParams) => {
            this.optimisticUIEditSess(sessId, editedParams)

            editedParams.id = sessId
            updateSessions([editedParams], () => {
              this.getAllDataDelayed()
            })
          }}
          onAddSession={() => {}}
          onAddTab={() => {}}
          onRemoveTab={() => {}}
        />

        {archivedSess && !!archivedSess.length && (
          <>
            <div style={{ paddingTop: 50 }} />

            <div style={{ width: "fit-content", margin: "0 auto" }}>
              <div className={classes.archivedSessionsTitle}>
                Archived Sessions
              </div>
              <Sessions
                sessions={archivedSess}
                removeText="Delete session"
                onRemoveSession={sessId => {
                  this.optimisticUISessDeleted(sessId)
                  removeSessions([sessId], () => this.getAllDataDelayed())
                }}
                onEditSess={(sessId, editedParams) => {
                  this.optimisticUIEditSess(sessId, editedParams)

                  editedParams.id = sessId
                  updateSessions([editedParams], () => {
                    this.getAllDataDelayed()
                  })
                }}
                showUnArchive={true}
                onUnArchive={sessId => {
                  this.optimisticUISessUnArchived(sessId)
                  unArchiveSessions([sessId], () => this.getAllDataDelayed())
                }}
                onAddSession={() => {}}
                onAddTab={() => {}}
                onRemoveTab={() => {}}
              />
            </div>
          </>
        )}
      </>
    )
  }

  optimisticUISessArchived(sessId) {
    const index = this.state.allSessions.findIndex(sess => sess.id === sessId)
    const allSessions = [...this.state.allSessions]
    allSessions[index].archived = true
    this.setState({
      allSessions: allSessions,
    })
  }
  optimisticUISessUnArchived(sessId) {
    const index = this.state.allSessions.findIndex(sess => sess.id === sessId)
    const allSessions = [...this.state.allSessions]
    allSessions[index].archived = false
    this.setState({
      allSessions: allSessions,
    })
  }
  optimisticUISessDeleted(sessId) {
    const index = this.state.allSessions.findIndex(sess => sess.id === sessId)
    const allSessions = [...this.state.allSessions]
    allSessions.splice(index, 1)
    this.setState({
      allSessions: allSessions,
    })
  }
  optimisticUIEditSess(sessId, editedParams) {
    const allSessions = [...this.state.allSessions]
    const index = allSessions.findIndex(sess => sess.id === sessId)
    const sess = { ...allSessions[index] }
    for (let key in editedParams) {
      sess[key] = editedParams[key]
    }
    allSessions[index] = sess
    this.setState({
      allSessions: allSessions,
    })
  }
}

export default Home
