import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import EditSession from "./editSession"
import classes from "./sessions.module.css"
import plusIcon from "../../images/plus.svg"

class Sessions extends React.Component {
  state = {
    selectedSessIdx: 0,
  }

  render() {
    if (!this.props.sessions) {
      return "Loading..."
    }
    return (
      <div className={classes.container}>
        {this.renderSessions()}
        {this.renderTabs()}
      </div>
    )
  }

  renderSessions() {
    const getSessionClasses = i => {
      var sessionClasses = classes.session
      if (i === this.state.selectedSessIdx) {
        sessionClasses += " " + classes.selectedSession
      }
      return sessionClasses
    }

    return (
      <div className={classes.sessionsContainer}>
        {this.renderHeader(() => {
          this.props.onAddSession()
        })}

        {this.props.sessions.map((sess, i) => (
          <div
            className={getSessionClasses(i)}
            key={i}
            onClick={() => {
              this.setState({ selectedSessIdx: i })
            }}
          >
            <img className={classes.sessionImg} src={sess.imageUrl} />
            <div style={{ justifySelf: "flex-start", paddingLeft: 10 }}>
              <div className={classes.sessionName}>{sess.name}</div>
              <div style={{ fontSize: 14, color: "gray", cursor: "default" }}>
                Changed {dayjs(sess.updatedAt).format("MM-DD-YY")}
              </div>
            </div>
            <EditSession
              name={sess.name}
              onEdited={editedParams => {
                this.props.onEditSess(sess.id, editedParams)
              }}
            />
          </div>
        ))}
      </div>
    )
  }

  renderTabs() {
    const getTabClasses = i => {
      var tabClasses = classes.tab
      if (i % 2 === 0) {
        tabClasses += " " + classes.oddTab
      }
      return tabClasses
    }

    var tabs = undefined
    if (this.state.selectedSessIdx < this.props.sessions.length) {
      tabs = this.props.sessions[this.state.selectedSessIdx].tabs
    }
    return (
      <div className={classes.tabsContainer}>
        {this.renderHeader(
          () => {
            this.props.onAddTab()
          },
          this.state.selectedSessIdx >= this.props.sessions.length,
          () => {
            this.props.onRemoveSession(
              this.props.sessions[this.state.selectedSessIdx].id
            )
          },
          this.state.selectedSessIdx < this.props.sessions.length
            ? this.props.removeText
            : "",
          this.props.showUnArchive ? "Unarchive session" : null
        )}

        {tabs && <div className={classes.tabCount}>{tabs.length} tabs</div>}
        {tabs && (
          <div className={classes.scrollableTabsContainer}>
            {tabs.map((tab, i) => (
              <div className={getTabClasses(i)} key={i}>
                <img className={classes.tabImg} src={tab.favIconUrl} />
                <div style={{ justifySelf: "flex-start", paddingLeft: 10 }}>
                  <div className={classes.tabTitle}>{tab.title}</div>
                  <div className={classes.tabUrl}>{tab.url}</div>
                </div>
                <div />
                {/* <img
                  className={classes.tabCloseButton}
                  src={closeIcon}
                  onClick={event => {
                    this.props.onRemoveTab()
                  }}
                /> */}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  renderHeader(onAdd, hideAdd, onRemove, removeText, unarchiveText) {
    hideAdd = true
    return (
      <div className={classes.header}>
        {!hideAdd && (
          <button className={classes.addButton} onClick={onAdd}>
            <img src={plusIcon} className={classes.addButtonIcon} />
          </button>
        )}

        {!!removeText && (
          <button className={classes.removeButton} onClick={onRemove}>
            {removeText}
          </button>
        )}
        {!!unarchiveText && (
          <button
            className={classes.unArchiveButton}
            onClick={() =>
              this.props.onUnArchive(
                this.props.sessions[this.state.selectedSessIdx].id
              )
            }
          >
            {unarchiveText}
          </button>
        )}
      </div>
    )
  }
}

Sessions.propTypes = {
  sessions: PropTypes.array,
  removeText: PropTypes.string.isRequired,
  onRemoveSession: PropTypes.func.isRequired,
  onEditSess: PropTypes.func.isRequired,
  showUnArchive: PropTypes.bool,
  onUnArchive: PropTypes.func,
  onAddSession: PropTypes.func.isRequired,
  onAddTab: PropTypes.func.isRequired,
  onRemoveTab: PropTypes.func.isRequired,
}

export default Sessions
