import React from "react"
import classes from "./modal.module.css"

const Modal = ({ open, children }) => {
  const showHideClassName =
    classes.modal + " " + (open ? classes.displayBlock : classes.displayNone)

  return (
    <div className={showHideClassName}>
      <section className={classes.modalMain}>{children}</section>
    </div>
  )
}

export default Modal
